/** @format */

import Vue from "vue";
import VueRouter from "vue-router";
// import Home from '../views/Home.vue'
import Index from "../components/Index.vue";
import Company from "../components/Company.vue";
Vue.use(VueRouter);

const routes = [
	{
		path: "/",
		name: "Index",
		component: Index,
	},
	{
		path: "/company",
		name: "Company",
		component: Company,
	},
];

const router = new VueRouter({
	mode: "history",
	base: process.env.BASE_URL,
	routes,
});

export default router;
