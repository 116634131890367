<template>
  <div class="">
    <h3>爱谱蒂康组学年末狂欢，报名抽奖赢大礼
    </h3>
    <p>
      （请填写正确信息以便获取奖品）
    </p>
    <van-form @submit="onSubmit">
      <van-field
        v-model="form.username"
        name="username"
        label="姓名"
        placeholder="姓名"
        required
        :rules="[{ required: true, message: '请填写姓名' }]"
      />
      <van-field
        v-model="form.telephone"
        type="telephone"
        name="telephone"
        label="电话"
        placeholder="电话"
        :rules="[{ required: true, message: '请填写电话' }]"
        required
      />
      <van-field
        v-model="form.school"
        type="school"
        name="school"
        label="院校"
        placeholder="院校"
        :rules="[{ required: true, message: '请填写您的院校' }]"
        required
      />
      <!-- <van-field
        v-model="form.email"
        type="email"
        name="email"
        label="邮箱"
        placeholder="邮箱"
        :rules="[{ required: true, message: '请填写你的邮箱' }]"
        required
      />
      <van-field
        v-model="form.wechat_number"
        type="wechat_number"
        name="wechat_number"
        label="微信号"
        placeholder="微信号"
        :rules="[{ required: true, message: '请填写你的微信号' }]"
        required
      />
      <van-field
        readonly
        clickable
        name="direction"
        v-model="form.direction"
        label="研究领域"
        placeholder="请选择"
        required
        :rules="[{ required: true, message: '请选择您的研究领域' }]"
        @click="showPicker = true"
      />
      <van-popup
        v-model="showPicker"
        position="bottom"
      >
        <van-picker
          show-toolbar
          :columns="columns"
          @confirm="onConfirm"
          @cancel="showPicker = false"
        />
      </van-popup>
      <van-field
        v-model="form.ProjectLeader"
        type="ProjectLeader"
        name="ProjectLeader"
        label="课题负责人"
        placeholder="课题负责人"
      />
      <van-field
        name="judge1"
        label="是否对组学研究感兴趣"
        ref="judge1"
        required
        @click="change11()"
        :rules="[{ required: true, message: '请选择' }]"
      >
        <template #input>
          <van-radio-group
            v-model="form.radio"
            direction="horizontal"
          >
            <van-radio name="yes">是</van-radio>
            <van-radio name="no">否</van-radio>
          </van-radio-group>
        </template>
      </van-field>
      <van-field
        name="judge2"
        label="近期是否考虑进行组学分析研究"
        v-bind:style="{ display: active}"
        required
      >
        <template #input>
          <van-radio-group
            v-model="form.radio1"
            direction="horizontal"
          >
            <van-radio name="yes">是</van-radio>
            <van-radio name="no">否</van-radio>
          </van-radio-group>
        </template>
      </van-field>
      <van-field
        name="judge3"
        label="开展组学研究的时间"
        v-bind:style="{ display: active}"
        required
      >
        <template #input>
          <van-radio-group
            v-model="form.radio3"
            direction="horizontal"
          >
            <van-radio name="3个月内">3个月内</van-radio>
            <van-radio name="3个月以上">3个月以上</van-radio>
          </van-radio-group>
        </template>
      </van-field>
      <van-field
        name="judge4"
        label="下次直播课程是否需要消息推送"
        v-bind:style="{ display: active}"
        required
      >
        <template #input>
          <van-radio-group
            v-model="form.radio4"
            direction="horizontal"
          >
            <van-radio name="yes">是</van-radio>
            <van-radio name="no">否</van-radio>
          </van-radio-group>
        </template>
      </van-field> -->
      <van-field
        v-model="form.message"
        rows="5"
        autosize
        label="备注"
        name="remarks"
        type="textarea"
        placeholder="请输入备注"
      />
      <div style="margin: 16px">
        <van-button
          round
          block
          type="info"
          native-type="submit"
        >报名抽奖</van-button>
      </div>
    </van-form>
  </div>
</template>

<script>
import { Dialog } from 'vant';
export default {
  name: "Company",
  components: {
  },
  data() {
    return {
      active: 'none',
      show: false,
      showPicker: false,
      form: {
        radio: "",
        radio1: "",
        radio3: "",
        radio4: "",
      },
      columns: [
        "基础医学",
        "临床医学",
        "流行病学",
        "中医学",
        "免疫",
        "炎症",
        "其他",
      ],
    };
  },
  method() {
  },
  methods: {
    change11() {
      if (this.form.radio == "yes") {
        this.active = ''
      } else if (this.form.radio == "no") {
        this.active = 'none'
      }
    },
    onConfirm(value) {
      this.form.direction = value;
      this.showPicker = false;
    },
    onSubmit(values) {
      console.log("submit", values);
      var axios = require("axios");
      var FormData = require("form-data");
      var data = new FormData();
      data = values;
      var config = {
        method: "post",
        url: "https://api.iproteome.com/oa/market/info/",
        headers: {},
        data: data,
      };

      axios(config)
        .then(function (response) {
          console.log(JSON.stringify(response.data));
          if (response.data.Success) {
            Dialog.alert({
              message: '提交成功',
              theme: 'round-button',
            }).then(() => {
              // on close
              location.href = response.data.data
            });
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
  },
};
</script>
<style  scoped>
p {
  font-size: 12px;
}
</style>